.info {
  top: 106%;
  left: 48%;
  z-index: 7;
  padding: 20px;
  max-width: 400px;
  min-width: 250px;
  max-height: 540px;
  text-align: center;

  .row {
    margin-bottom: 25px;

    .close {
      width: 28px;
      height: 28px;
      margin-bottom: 40px;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 30;
    }
  }

  .title {
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    flex-direction: column;
    // max-width: 200px;
    text-align: start;
    gap: 15px;

    .content-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      h5 {
        color: rgba(115, 115, 115, 0.9);
      }

      .buttons {
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 10px;
        z-index: 10;

        p {
          font-size: 15px;
        }


      }
    }
  }
}

@media screen and (max-width: 480px) {
  .info {
    left: 48%;
    top: 90%;
  }
}
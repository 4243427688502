.Login {
  position: absolute;
  z-index: 10;
  left: 40%;
  top: 30%;
  padding: 30px 90px;
  max-height: 600px;
  background-color: rgba(18, 18, 18, 0.9);
  backdrop-filter: blur(30px);
  border-radius: var(--b-r-l);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 382px;

  .close {
    width: 28px;
    height: 28px;
    margin-bottom: 40px;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      margin-bottom: 10px;
      cursor: pointer;
    }

    input {
      padding: 5px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }

    .column,
    .column-input {
      display: flex;
      flex-direction: column;
    }

    .column {
      align-items: center;
    }

    .column-input {
      align-items: flex-start;
    }

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    .buttons {
      margin-top: 10px;
    }
    button {
      color: #fff;
      max-width: 80px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 480px) {
  .Login {
    left: 5px;
    top: 15%;
    max-width: 100vw;
  }
}

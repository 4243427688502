.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
  padding: 0 48px;
  width: 100vw;

  .control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 35px;

    .time-widget {
      cursor: pointer;
      border-radius: var(--b-r-s);
      color: var(--text-color);
      background-color: var(--main-bg);
      font-weight: 500;
      padding: 6px;
      transition: background 0.3s ease;

      &:hover {
        background: var(--dark-bg);
        color: var(--text-color);
      }

      &:active {
        background-color: var(--main-bg);
        color: var(--text-color);
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .header {
    padding: 0;
    .control {
      gap: 15px;
      .time-widget {
        display: none;
      }
    }
  }
  .move {
    min-width: 300px;
    width: 300px;
  }
}

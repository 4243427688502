.artist-card {
  position: absolute;
  bottom: 5%;
  left: 5%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: var(--b-r-s);

  .title {
    margin-bottom: 10px;
    color: rgba(115, 115, 115, 0.9);

    span {
      color: var(--text-color);
    }
  }

  .contacts {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 7px;
  }
}

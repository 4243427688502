.popoverAction {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 200px;
  z-index: 3;
  cursor: pointer;

  &:hover .circleHover {
    border-color: var(--main-color);
  }

  &:hover .circleHover::before {
    content: '';
    background-color: var(--main-color);
    border-radius: 50%;
    height: 18px;
    transition: all 0.2s ease-in-out;
    width: 18px;
  }

  &:hover .popoverCard {
    transform: scale(1);
  }

  .circleHover {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 3px solid #fff;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
  }

  .popoverCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--b-r-s);
    margin-top: 8px;
    min-width: 120px;
    padding: 8px;
    transform: scale(0);
    transition: all 0.2s ease-in-out;

    h5 {
      margin-bottom: 10px;
    }
  }
}

@media  screen and (max-width: 480px) { 
  .popoverAction {
    left: unset;
    top: unset;
    width: 50px;
  }
  .popoverCard {
    min-width: 80px !important;
  }
}

.theme {
  min-height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -5;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: brightness(0.75);
    filter: contrast(120%);
  }

  .video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    z-index: -1;
  }

  .isVisible {
    visibility: visible;
  }
  .isHidden {
    visibility: hidden;
    // display: none;
  }
}

@media screen and (max-width: 480px) {
}

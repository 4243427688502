.mixer {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  max-height: 380px;
  top: 65%;
  left: 5%;
  cursor: default;
  padding-bottom: 10px;
  .handle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    cursor: move;
  }

  .sounds {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 5px 20px;
    padding: 10px;
    background-color: hsla(0, 0%, 100%, 0.05);
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    border-radius: var(--b-r-m);
    cursor: default;
    font-size: 16px;
    overflow-y: scroll;
    max-height: 295px;
    &::-webkit-scrollbar {
      display: none;
    }

    .sound {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      height: 40px;
    }
  }
}

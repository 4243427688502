.main {
  min-height: 100vh;

  .navigation {
    flex: 0 0 100px;
  }
}

@media screen and (max-width: 480px) {
  .main {
    max-width: 370px;
    max-height: 600px;
    overflow: hidden;
    .navigation {
      flex: 0 0 10px !important;
    }
  }
}

.move {
  top: 70%;
  left: 25%;
  padding: 15px;
  width: 400px;
  min-width: 400px;

  .first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  .move {
    width: 300px;
    min-width: unset !important;
  }
}

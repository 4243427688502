.timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 30%;
  top: 106%;
  padding: 20px 10px;
  min-width: 220px;
  font-size: 35px;
  font-weight: 500;

  .close {
    
    svg {
      width: 20px;
      height: 20px;
    }

    position: absolute;
    right: 10px;
  }

  .time {
    margin-top: 20px;
    display: flex;
    margin-bottom: 15px;
  }

  .buttons {
    display: flex;
    gap: 7px;
    .active {
      z-index: 31;
    }
    button {
      background-color: transparent;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
@media screen and (max-width: 480px) {
  .timer {
    top: 90%;
    left: 5%;
  }
}

:root {
  --max-width: 1100px;

  --b-r-l: 10px;
  --b-r-m: 8px;
  --b-r-s: 5px;

  --dark-bg: #333;
  --main-bg: rgba(18, 18, 18, 0.8);
  --bright-bg: #d9d9d9;
  --text-color: #f5f5f5;
  --accent: #f80;
  --main-color: #ffac4d;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color-scheme: dark;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: var(--dark-bg);
  border-radius: 100px;
  scrollbar-gutter: stable;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-bg);
  border-radius: 100px;
}

body {
  min-height: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  font-weight: normal;
  font-size: 14px;
  font-family: Roboto, serif;
  color: var(--text-color);
  background-color: var(--dark-bg);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  transition: scrollbar 0.3s ease-out;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  border: none;
}

h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

a, .qrcode {
  color: var(--main-color);

  &:hover {
    color: var(--accent);
  }
}

.widget {
  font-family: Roboto, serif;
  position: absolute;
  /* top: 10px;
  left: 15%; */
  border-radius: var(--b-r-l);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(30px);
  cursor: move;

  z-index: 7;
}

@media screen and (max-width: 480px) {
  .body {
    max-width: 370px;
    max-height: 600px;
    overflow: hidden;
  }
  h3 {
    font-size: 15px;
  }
  h4 {
    font-size: 12px;
  }
  h5 {
    font-size: 10px;
  }
}

.nav {
  display: flex;
  flex-direction: column;

  .nav-icon {
    width: 42px;
    height: 54px;
    background-color: var(--main-bg);
    cursor: pointer;
    border-radius: 0;

    &:hover {
      background: var(--dark-bg);
      color: var(--text-color);
    }

    &:active {
      background-color: var(--main-bg);
      color: var(--text-color);
    }
    &:first-child {
      border-radius: var(--b-r-l) var(--b-r-l) 0 0;
    }

    &:last-child {
      border-radius: 0 0 var(--b-r-l) var(--b-r-l);
    }
  }
}


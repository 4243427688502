.player {
  display: flex;
  align-items: center;
  flex-direction: column;

  .buttons {
    display: flex;

    .prev {
      border-radius: var(--b-r-s) 0 0 var(--b-r-s);

      &.isVolume {
        border-radius: var(--b-r-s) 0 0;
      }
    }

    .play {
      border-radius: 0;
    }

    .next {
      border-radius: 0;
    }

    .volume {
      border-radius: 0 var(--b-r-s) var(--b-r-s) 0;

      &.isVolume {
        border-radius: 0 var(--b-r-s) 0 0;
      }
    }
  }

  .card {
    position: absolute;
    top: 57px;
    height: 34px;
    width: 144px;
    display: flex;
    background-color: var(--main-bg);
    border-radius: 0 0 var(--b-r-s) var(--b-r-s);
    padding: 15px 7px;
    transform: translateY(0);
    opacity: 0;
    transition: all 0.1s ease-out;

    &.isVisible {
      transform: translateY(1px);
      opacity: 1;
    }
  }
}

.button {
  border-radius: var(--b-r-s);
  background: var(--main-bg);
  color: var(--text-color);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 1.54px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s ease;

  &.medium {
    min-width: 90px;
    min-height: 30px;
    padding: 6px 20px;
  }

  &.small {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
  }

  &:hover {
    background-color: var(--dark-bg);
    color: var(--text-color);
  }

  &:active {
    background-color: var(--main-bg);
    color: var(--text-color);
  }
}

.todo {
  top: 0%;
  left: 5%;
  cursor: move;
  z-index: 5;
  // background-color: var(--main-bg);
  padding: 20px;
  // border-radius: 15px;
  // font-family: inherit;

  .close {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 20px;
    z-index: 20;
  }

  .react-kanban-board {
    margin-top: 15px;
  }

  .react-kanban-column-header {
    color: var(--text-color);
    text-align: center;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
    min-width: 120px;
    max-width: 150px;
  }

  .react-kanban-column {
    padding: 15px;
    margin: 5px;
    cursor: default;

    .react-kanban-card-adder-button {
      width: 100%;
      padding: 3px;
      margin-bottom: 10px;
      border-radius: var(--b-r-s);
      background-color: transparent;
      color: var(--accent);

      cursor: pointer;
      font-weight: bold;
      font-size: 20px;
      transition: 0.3s ease;

      &:hover {
        background-color: var(--text-color);
        color: var(--dark-bg);
      }
    }
  }

  .react-kanban-card {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: var(--b-r-s);
    background-color: var(--text-color);
    color: var(--dark-bg);
    padding: 10px;
    margin-bottom: 7px;
    min-width: 150px;
    max-width: 150px;
  }

  .react-kanban-card__title {
    display: flex;
    justify-content: space-between;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    min-width: 100px;

    span:last-child {
      transition: 0.3s ease;

      &:hover {
        color: gray;
      }
    }
  }

  .react-kanban-card--dragging {
    // transform: rotate(90deg);
  }

  .react-kanban-card__description {
    font-size: 12px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;

    input,
    button {
      background-color: transparent;
      border: none;
      color: #fff;
      margin-bottom: 5px;
      padding: 5px;
    }

    button {
      font-size: 14px;
      cursor: pointer;
    }

    input {
      width: 100%;
    }
  }
}

@media screen and (max-width: 480px) {
  .todo {
    max-width: 220px;
    overflow-x: scroll;
  }
}

.notes {
  top: 0%;
  left: 70%;
  padding: 20px;
  max-width: 350px;
  min-width: 300px;
  min-height: 340px;
  max-height: 740px;
  text-align: center;

  .row {
    margin-bottom: 10px;

    .close {
      width: 28px;
      height: 28px;
      margin-bottom: 40px;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 20;
    }
  }

  .ant-list {
    // max-height: 300px;
    overflow-y: auto;
    margin-bottom: 5%;

    .ant-list-empty-text {
      color: var(--text-color);
    }

    .ant-list-item {
      display: flex;
      align-items: flex-start;
      text-align: start;
      padding: 10px;
      cursor: pointer;

      &.checked .ant-list-item-meta-content {
        text-decoration: line-through;
        filter: blur(1px);
        color: var(--text-color);
      }

      .closeItem {
        width: 22px;
        height: 22px;
        cursor: pointer;
        z-index: 5;
        color: var(--text-color);
      }

      .ant-list-item-meta-title {
        color: var(--text-color);
      }

      .ant-list-item-meta-description {
        color: var(--bright-bg);
      }
    }
  }

  .buttons {
    z-index: 20;
    span {
      background-color: transparent;
      margin-bottom: 10px;

      &:hover,
      &:focus,
      &.ant-input-affix-wrapper-focused {
        border-color: var(--accent);
      }

      input,
      textarea {
        background-color: transparent;
        color: var(--text-color);

        &::placeholder {
          color: rgba(225, 225, 225, 0.6);
        }
      }
    }
  }
}

.scenes {
  width: 350px;
  right: 15px;
  left: unset;
  top: unset;
  cursor: default;
  min-width: 300px;
  min-height: 400px;
  padding: 15px;
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    .close {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 10px;
      z-index: 20;
    }
  }
  .container {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-direction: column;
    max-height: 600px;
    overflow-y: scroll;
    position: relative;
    width: 100%;

    .scene {
      position: relative;
      background-color: #0005;
      border-radius: 15px;
      cursor: pointer;
      position: relative;
      width: 100%;
      background-color: #0005;
      z-index: 20;
      .scene-img {
        border-radius: 15px;
        height: 190px;
        object-fit: cover;
        width: 100%;
        filter: brightness(30%);
        transition: all 0.2s ease-out;

        &:hover {
          filter: none;
        }

        &.isActive {
          filter: brightness(90%);
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .scenes {
    max-width: 300px;
    min-width: unset !important;
    right: 0;
  }
}
